import { LogoIcon } from "../Shared/SVGComponents";

function FooterLogo(){
    return (
        <div className="flex items-center font-main text-base2 font-bold text-white">
            <LogoIcon />
            <h1>Lidabro</h1>
        </div>
    )
}

export default FooterLogo;