import { useState, useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation } from 'react-i18next';

function HeaderLanguage() {
    const { i18n } = useTranslation();
    // const [language, setLanguage] = useState('en');
    const {language, changeLanguage} = useContext(LanguageContext);
    

    function handleClick(event){
        changeLanguage(event.target.id);
        i18n.changeLanguage(event.target.id);
    }

    return (
        <div className="gap-2 font-main md:text-base lg:text-xl font-normal text-gray-lidabro-dark cursor-pointer hidden lg:flex" onClick={handleClick}>
            {language == 'en' ? <p id='en' className="text-black-lidabro">En</p> : <p id='en'>En</p>}
            {language == 'iw' ? <p id='iw' className="text-black-lidabro">עִברִית</p> : <p id='iw'>עִברִית</p>}
        </div>
    )
}

export default HeaderLanguage;