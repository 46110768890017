import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import "./Fonts/Poppins-Regular.ttf";
import './i18n'

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <React.Suspense fallback="Loading">
      <App />
    </React.Suspense>
  </React.StrictMode>
  );
