import { useTranslation } from 'react-i18next'

function HeaderMenuItem(props) {
    const { t } = useTranslation();

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    
    return (
        <div className="px-3 py-2.5 rounded-lg font-main md:text-base lg:text-xl font-normal text-black-lidabro cursor-pointer">
            <p onClick={() => scrollToSection(props.link)}>{t(props.name)}</p>
        </div>
    )
}

export default HeaderMenuItem;