import ForShoppersSignUp from "./ForShoppersSignUp";
import ForShoppersAdvantages from "./ForShoppersAdvantages";
import ForShoppersSubscribe from "./ForShoppersSubscribe";

function ForShoppers(props) {
    return (
        <div id="ForShoppers" className="flex flex-col gap-10 mx-7 mb-10 lg:mx-0 lg:gap-[96px] xl:gap-[140px] lg:mb-[105px] lg:px-40 scroll-mt-[150px] lg:scroll-mt-[300px]">
            <ForShoppersSignUp />
            <ForShoppersAdvantages />
            <ForShoppersSubscribe />
        </div>
    )
}

export default ForShoppers;