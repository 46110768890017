import { useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation } from "react-i18next";
import Icon from "../Shared/Icon";
import { LiveIcon, CameraIcon, UsersIcon, ChatIcon, GraphIcon } from "../Shared/SVGComponents";

function ForSellersAdventages(props) {
    const { language, rowDirection, colDirection, textDirection, itemsDirection } = useContext(LanguageContext);
    const { t } = useTranslation();

    return (
        <div className={`flex ${rowDirection()} gap-9`}>
            <div className="flex flex-col gap-16 p-12 font-main text-xl font-normal text-gray-lidabro-dark flex-[1_0_0%] bg-white rounded-3xl">
                <div className={`flex ${rowDirection()} items-center gap-7`}>
                    <Icon image={<LiveIcon />} />
                    <p>{t("Connect to your customer in real time")}</p>
                </div>
                <div className={`flex ${rowDirection()} items-center gap-7`}>
                    <Icon image={<CameraIcon />} />
                    <p>{t("Provide personalized product demonstrations and consultation")}</p>
                </div>
                <div className={`flex ${rowDirection()} items-center gap-7`}>
                    <Icon image={<UsersIcon />} />
                    <p>{t("Reach a wider audience")}</p>
                </div>
                <div className={`flex ${rowDirection()} items-center gap-7`}>
                    <Icon image={<ChatIcon />} />
                    <p>{t("Grow engagement and loyalty")}</p>
                </div>
                <div className={`flex ${rowDirection()} items-center gap-7`}>
                    <Icon image={<GraphIcon />} />
                    <p>{t("Boost conversions")}</p>
                </div>
            </div>
            <div className="hidden lg:block lidabro-overlay flex-[1_0_0%] relative before:w-full before:h-full before:block before:absolute before:top-0 before:left-0 before:rounded-3xl">
                <img className="rounded-3xl min-h-full max-h-full object-cover object-[40%]" src="happy-user.png" alt="Happy User" />
            </div>
        </div>
    )
}

export default ForSellersAdventages;