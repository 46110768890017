import { useState, useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation } from 'react-i18next';

function HeaderMenuMobileLanguage() {
    const { i18n } = useTranslation();
    const {language, changeLanguage} = useContext(LanguageContext);

    function handleClick(event){
        changeLanguage(event.target.id);
        i18n.changeLanguage(event.target.id);
    }

    return (
        <div className="gap-5 font-main text-sm font-normal text-gray-lidabro-dark cursor-pointer flex flex-col" onClick={handleClick}>
            {language == 'en' ? <p id='en' className="text-black-lidabro">English</p> : <p id='en'>English</p>}
            {language == 'iw' ? <p id='iw' className="text-black-lidabro">עִברִית</p> : <p id='iw'>עִברִית</p>}
        </div>
    )
}

export default HeaderMenuMobileLanguage;