import ForSellersAbout from "./ForSellersAbout";
import ForSellersAdventages from "./ForSellersAdventages";

function ForSellers(props) {
    return (
        <div id="ForSellers" className="flex flex-col gap-10 mx-7 mb-10 lg:mx-0 lg:gap-[96px] xl:gap-[199px] lg:mb-[140px] lg:px-40 scroll-mt-[150px] lg:scroll-mt-[300px]">
            <ForSellersAbout />
            <ForSellersAdventages />
        </div>
    )
}

export default ForSellers;