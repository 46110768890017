import { useState, useEffect, useRef } from "react";
import { PlayButtonIcon, PlayButtonIconMobile } from "../Shared/SVGComponents";

function AboutLidabroPreview(props) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [autoplay, setAutoplay] = useState(false);
    const ref = useRef(null);

    function handleClick() {
        const nextIsPlaying = !isPlaying;
        setIsPlaying(nextIsPlaying);

        if (nextIsPlaying) {
            ref.current.play();
        } else {
            ref.current.pause();
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          setAutoplay(true);
        }, 3000);
    
        return () => clearTimeout(timer);
      }, []);
    
    return (
        <div className="flex items-center justify-center relative">
            <div className="flex items-center justify-center relative">
                <div className="z-10 hidden lg:block">
                    <img className="xl:max-w-[1125px] xl:max-h-[700px] xl:min-w-[1125px] xl:min-h-[700px] lg:max-w-[1020px] lg:min-w-[1020px] lg:min-h-[519px] lg:max-h-[640px]" src="iPad.png" alt="" />
                </div>
                <div className="hidden lg:block absolute z-10" onClick={handleClick}>
                    {isPlaying ? null : <PlayButtonIcon />}
                </div>
                <div className="block lg:hidden absolute z-10" onClick={handleClick}>
                    {isPlaying ? null : <PlayButtonIconMobile />}
                </div>
                <div className="px-7 lg:px-0 lg:absolute xl:w-[965px] lg:w-[880px]" onClick={handleClick}>
                    <video  autoPlay={autoplay}
                            muted
                            poster="/videos/Lidabro_Preview_Poster.png"
                            className="border-4 border-violet-lidabro rounded-3xl lg:border-none lg:rounded-none"
                            ref={ref}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}>
                        <source
                        src="/videos/Lidabro_Preview_Cropped.mp4"
                        type="video/mp4"
                        />
                    </video>
                </div> 
            </div>
            <div className="absolute lidabro-blue-circle-mobile -top-[350px] lg:-top-[169px] lg:lidabro-blue-circle w-[245px] h-[245px] lg:w-3/4 lg:h-[500px] left-0 -z-10">
            </div>
            <div className="absolute lidabro-green-circle-mobile -top-[350px] lg:-top-[169px] lg:lidabro-green-circle w-[245px] h-[245px] lg:w-1/2 lg:h-[500px] right-0 -z-10">
            </div>
        </div>
    )
}

export default AboutLidabroPreview;