import { useTranslation } from "react-i18next";

function AboutHeader(){
    const { t } = useTranslation();

    return (
        <div className="flex justify-center text-center font-main text-2xl font-medium text-white lg:text-5xl lg:font-normal lg:text-black-lidabro">
            <p>{t("AboutHeader")}</p>
        </div>
    )
}

export default AboutHeader;