import { useState } from 'react';
import { Hamburger, Xmark } from '../Shared/SVGComponents';
import HeaderLogo from './HeaderLogo';
import HeaderMenuMobileItem from './HeaderMenuMobileItem';
import HeaderMenuMobileLanguage from './HeaderMenuMobileLanguage';

const HeaderMenuMobile = () => {
    const [isOpen, setIsOpen] = useState(false);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="lg:hidden">
            <button
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={toggleMenu}
            >
                {isOpen ? (
                    <Xmark />
                ) : (
                    <Hamburger />
                )}
            </button>

            {isOpen && (
                <div className="bg-mobile-menu absolute top-0 left-0 w-screen h-screen z-50 flex flex-col gap-9">
                    <div className='flex items-center justify-between px-11 py-12 bg-mobile-menu-header'>
                        <HeaderLogo />
                        <div onClick={toggleMenu}>
                            <Xmark />
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-between py-20 h-full'>
                        <div className='flex flex-col items-center font-main text-sm font-medium'>
                            <HeaderMenuMobileItem name={'About'} link={'About'} setIsOpen={setIsOpen}/>
                            <HeaderMenuMobileItem name={'For Shoppers'} link={'ForShoppers'} setIsOpen={setIsOpen}/>
                            <HeaderMenuMobileItem name={'For Sellers'} link={'ForSellers'} setIsOpen={setIsOpen}/>
                            <HeaderMenuMobileItem name={'Contact Us'} link={'ContactUs'} setIsOpen={setIsOpen}/>
                        </div>
                        <HeaderMenuMobileLanguage />
                        <div className='flex flex-col gap-5 w-full px-14'>
                            <div>
                                <hr className='w-full border-r border-black-lidabro rounded-sm'/>
                            </div>
                            <p className='text-black-lidabro font-main text-xs font-normal text-center'>© Lidabro {currentYear}. All right reserved</p>
                        </div>
                    </div>
                    
                </div>
            )}
        </div>
    );
};

export default HeaderMenuMobile;