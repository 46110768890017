import { useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import HeaderMenuItem from "./HeaderMenuItem";

function HeaderMenu(){
    const { language, rowDirection, colDirection, textDirection, itemsDirection } = useContext(LanguageContext);

    return (
        <div className={`gap-6 hidden lg:flex ${rowDirection()}`}>
            <HeaderMenuItem name={'About'} link={'About'}/>
            <HeaderMenuItem name={'For Shoppers'} link={'ForShoppers'}/>
            <HeaderMenuItem name={'For Sellers'} link={'ForSellers'}/>
            <HeaderMenuItem name={'Contact Us'} link={'ContactUs'}/>
        </div>
    )
}

export default HeaderMenu;