import { useState, useEffect } from "react";
import { LogoIcon, LogoIconMobile } from "../Shared/SVGComponents";

function HeaderLogo(){
    const [minWidth, setMinWidth] = useState(window.screen.availWidth);
    useEffect(() => {
        const handleResize = () => {
            setMinWidth(window.screen.availWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {minWidth > 768 ? 
                (<div className="hidden md:flex items-center font-main text-base2 font-bold text-black-lidabro">
                    <div>
                        <LogoIcon />
                    </div>
                    <h1>Lidabro</h1>
                </div>) : (
                <div className="flex md:hidden items-center font-main text-xl font-bold text-black-lidabro">
                    <div>
                        <LogoIconMobile />
                    </div>
                    <h1>Lidabro</h1>
                </div>)}
            
            
        </>
        
    )
}

export default HeaderLogo;