import { useTranslation } from "react-i18next";

function AboutCategory({category}) {
    const { t } = useTranslation();
    
    return (
        <div className="flex flex-col gap-4 font-main text-xl font-medium text-white text-center min-w-32 max-w-32 category-shadow">
            <div>
                <img style={{backgroundImage: `url(${category.image})`}} className="object-cover w-32 h-32 rounded-full border-4 border-white border-opacity-50 bg-center bg-origin-border bg-cover bg-no-repeat bg-gray-lidabro-light" src={category.image} alt={category.name} />
            </div>
            <p>{t(category.name)}</p>
        </div>
    )
}

export default AboutCategory;