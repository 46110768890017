import Categories from './AboutCategoriesList.json'
import AboutCategory from './AboutCategory';

function AboutCategories() {
    return (
        <div className='w-screen overflow-hidden'>
            <div className='flex gap-4.5 animate-lidabro-slider'>
                {Categories.map((category)=><AboutCategory key={category.id} category={category} />)}
            </div>
        </div>
    )
}

export default AboutCategories;