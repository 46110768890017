import './App.css';
import Main from './Pages/Main'
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import { Context } from './Components/HOC/Language';

function App() {
	return (
        <Context>
            <div className='bg-page'>
                <div className='flex flex-col items-center -z-50'>
                    <Header />
                    <div className='z-10 relative'>
                        <Main />
                    </div>
                    <Footer />
                </div>
            </div>
        </Context>
	)
}

export default App;
