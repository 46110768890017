import About from "../Components/About/About";
import ForShoppers from "../Components/ForShoppers/ForShoppers";
import ForSellers from "../Components/ForSellers/ForSellers";
import ContactUs from "../Components/ContactUs/ContactUs";

function Main(){
    return (
        <>
          <About />
          <ForShoppers />
          <ForSellers />
          <ContactUs />
        </>
    )
}

export default Main;