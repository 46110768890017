import { useTranslation } from "react-i18next";

function FooterMenuItem(props) {
    const { t } = useTranslation();

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <p className="px-3 py-2.5 cursor-pointer" onClick={() => scrollToSection(props.link)}>{t(props.name)}</p>
    )
}

export default FooterMenuItem;