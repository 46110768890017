import { useState, useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation } from "react-i18next";
import { submitEmail } from "../../services/fetch";
import { isEmail, isEmpty } from "validator";
import FooterLogo from "./FooterLogo";
import FooterMenuItem from "./FooterMenuItem";

function Footer(){
    const defaultFormData = {
        email: ''
    }
    const validationStatusDefault = {
        email: false
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [formData, setFormData] = useState(defaultFormData);
    const [validationStatus, setValidationStatus] = useState(validationStatusDefault);
    const { language, rowDirection, colDirection, textDirection, itemsDirection } = useContext(LanguageContext);
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValidationStatus(validationStatusDefault);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBack = (e) => {
        setFormState('default');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEmpty(formData.email)){
            return
        }

        if (!isEmail(formData.email)){
            console.log("Not email");
            setValidationStatus((prevState => ({
                ...prevState,
                email: true
            })));
        }

        if (isEmail(formData.email)){
            let successful = submitEmail(formData);
            if (successful == 'true') {
                setFormData(defaultFormData);
                setFormState('submitted');
            } else {
                setFormState('error');
            }
        }
    };
    
    return (
        <footer className="w-screen -mt-6 bg-blue-lidabro-dark lg:bg-blue-lidabro">
            <div className="flex flex-col items-center gap-12 p-14 xl:py-12 xl:px-40">
                <div className={`xl:w-full flex flex-col ${rowDirection('xl')} justify-between font-main text-sm font-normal text-white`}>
                    <div className="flex flex-col gap-6">
                        <div className={`flex flex-col items-center ${itemsDirection('xl')} gap-2`}>
                            <FooterMenuItem name={'About'} link={'About'}/>
                            <FooterMenuItem name={'For Shoppers'} link={'ForShoppers'}/>
                            <FooterMenuItem name={'For Sellers'} link={'ForSellers'}/>
                            <FooterMenuItem name={'Contact Us'} link={'ContactUs'}/>
                        </div>
                        <div className="hidden xl:block">
                            <FooterLogo />
                        </div>
                    </div>
                    <div className={`flex-col ${textDirection()} justify-between max-w-[400px] hidden xl:flex`}>
                        <p className="text-lg hidden xl:block">{t("Subscribe to our newsletter")}</p>
                        <p className="text-opacity-85">{t("Stay tuned")}</p>
                        <div className="flex flex-col justify-center">
                            <form className={`w-full flex ${rowDirection()} items-center justify-center gap-2`} onSubmit={handleSubmit}>
                                <input name="email" value={formData.email} onChange={handleChange} type="text" className={`w-full px-5 py-2.5 rounded-3xl outline-none bg-opacity-20 bg-white border border-white border-opacity-50 ${textDirection()} placeholder:text-white placeholder:font-main placeholder:text-sm placeholder:font-medium`} placeholder={t("Your email address...")}/>
                                <button type="submit" className="w-fit px-5 py-3 bg-white rounded-3xl font-main text-sm font-medium text-blue-lidabro-dark text-nowrap">{t("Sign Up")}</button>
                            </form>
                            {validationStatus['email'] && <p className="px-3 py-1 text-sm text-white">Email is invalid</p>}
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <hr className='border-t border-gray-lidabro-divider'/>
                </div>
                <p className="text-center font-main text-xs font-normal text-white">© Lidabro {currentYear}. All rights reserved</p>
                <div className="visible xl:hidden">
                    <FooterLogo />
                </div>
            </div>
        </footer>
    )
}

export default Footer;