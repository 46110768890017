import { createContext, useState } from "react";

export const LanguageContext = createContext();

export const Context = (props) =>{
    const [language, setLanguage] = useState('en');

    const changeLanguage = (language) => {
        setLanguage(language);
    }

    const rowDirection = (breakpoint) => {
        if (language === 'iw'){
            switch (breakpoint) {
                case 'sm':
                    return 'sm:flex-row-reverse'
                case 'md':
                    return 'md:flex-row-reverse'
                case 'lg':
                    return 'lg:flex-row-reverse'
                case 'xl':
                    return 'xl:flex-row-reverse'
                case '2xl':
                    return '2xl:flex-row-reverse'
                default:
                    return 'flex-row-reverse';
            }
        } else {
            switch (breakpoint) {
                case 'sm':
                    return 'sm:flex-row'
                case 'md':
                    return 'md:flex-row'
                case 'lg':
                    return 'lg:flex-row'
                case 'xl':
                    return 'xl:flex-row'
                case '2xl':
                    return '2xl:flex-row'
                default:
                    return 'flex-row';
            }
        }
    }

    const colDirection = (breakpoint) => {
        if (language === 'iw'){
            switch (breakpoint) {
                case 'sm':
                    return 'sm:flex-col-reverse'
                case 'md':
                    return 'md:flex-col-reverse'
                case 'lg':
                    return 'lg:flex-col-reverse'
                case 'xl':
                    return 'xl:flex-col-reverse'
                case '2xl':
                    return '2xl:flex-col-reverse'
                default:
                    return 'flex-col-reverse';
            }
        } else {
            switch (breakpoint) {
                case 'sm':
                    return 'sm:flex-col'
                case 'md':
                    return 'md:flex-col'
                case 'lg':
                    return 'lg:flex-col'
                case 'xl':
                    return 'xl:flex-col'
                case '2xl':
                    return '2xl:flex-col'
                default:
                    return 'flex-col';
            }
        }
    }

    const textDirection = (breakpoint) => {
        if (language === 'iw'){
            switch (breakpoint) {
                case 'sm':
                    return 'sm:text-right'
                case 'md':
                    return 'md:text-right'
                case 'lg':
                    return 'lg:text-right'
                case 'xl':
                    return 'xl:text-right'
                case '2xl':
                    return '2xl:text-right'
                default:
                    return 'text-right';
            }
        } else {
            switch (breakpoint) {
                case 'sm':
                    return 'sm:text-left'
                case 'md':
                    return 'md:text-left'
                case 'lg':
                    return 'lg:text-left'
                case 'xl':
                    return 'xl:text-left'
                case '2xl':
                    return '2xl:text-left'
                default:
                    return 'text-left';
            }
        }
    }

    const itemsDirection = (breakpoint) => {
        if (language === 'iw'){
            switch (breakpoint) {
                case 'sm':
                    return 'sm:items-end'
                case 'md':
                    return 'md:items-end'
                case 'lg':
                    return 'lg:items-end'
                case 'xl':
                    return 'xl:items-end'
                case '2xl':
                    return '2xl:items-end'
                default:
                    return 'items-end';
            }
        } else {
            switch (breakpoint) {
                case 'sm':
                    return 'sm:items-start'
                case 'md':
                    return 'md:items-start'
                case 'lg':
                    return 'lg:items-start'
                case 'xl':
                    return 'xl:items-start'
                case '2xl':
                    return '2xl:items-start'
                default:
                    return 'items-start';
            }
        }
    }

    const itemsReverseDirection = (breakpoint) => {
        if (language === 'iw'){
            switch (breakpoint) {
                case 'sm':
                    return 'sm:items-start'
                case 'md':
                    return 'md:items-start'
                case 'lg':
                    return 'lg:items-start'
                case 'xl':
                    return 'xl:items-start'
                case '2xl':
                    return '2xl:items-start'
                default:
                    return 'items-start';
            }
        } else {
            switch (breakpoint) {
                case 'sm':
                    return 'sm:items-end'
                case 'md':
                    return 'md:items-end'
                case 'lg':
                    return 'lg:items-end'
                case 'xl':
                    return 'xl:items-end'
                case '2xl':
                    return '2xl:items-end'
                default:
                    return 'items-end';
            }
        }
    }

    const selfDirection = (breakpoint) => {
        if (language === 'iw'){
            switch (breakpoint) {
                case 'sm':
                    return 'sm:self-end'
                case 'md':
                    return 'md:self-end'
                case 'lg':
                    return 'lg:self-end'
                case 'xl':
                    return 'xl:self-end'
                case '2xl':
                    return '2xl:self-end'
                default:
                    return 'self-end';
            }
        } else {
            switch (breakpoint) {
                case 'sm':
                    return 'sm:self-start'
                case 'md':
                    return 'md:self-start'
                case 'lg':
                    return 'lg:self-start'
                case 'xl':
                    return 'xl:self-start'
                case '2xl':
                    return '2xl:self-start'
                default:
                    return 'self-start';
            }
        }
    }

    const value = {
        language,
        changeLanguage,
        rowDirection,
        colDirection,
        textDirection,
        itemsDirection,
        itemsReverseDirection,
        selfDirection
    }

    return <LanguageContext.Provider value={value}>
        {props.children}
    </LanguageContext.Provider>
}