import DOMPurify from 'dompurify';
import axios from 'axios';
const SERVER = getServer();

function getServer(){
	switch (process.env.REACT_APP_NODE_ENV) {
		case 'development':
			return 'http://localhost:4000';
		case 'stage':
			return process.env.REACT_APP_SERVER_STAGE;
		case 'production':
			return process.env.REACT_APP_SERVER_PRODUCTION;
	}
}

export const submitEmail = async (data) => {
    let result = false;
    const searchObject = {
        api: SERVER,
        endpoint: `subscriptions/email`,
    };
    const sanitizedData = getSanitizedData(data);
    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
    try {
        const response = await axios.post(url.href, data, {
            headers: {
            'Content-Type': 'application/json'
          }});
          if (response.ok) {
            // Email sent successfully
            console.log('Email sent successfully');
            result = true;
          } else {
            // Error handling
            console.error('Failed to send email');
          }
    } catch (err) {
        console.error('Error:', err);
    }
    return result;
}

export const submitContacts = async (data) => {
    let result = false;  
    const searchObject = {
        api: SERVER,
        endpoint: `subscriptions/contact`,
    };
    const sanitizedData = getSanitizedData(data);

    const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
    try {
        const response = await axios.post(url.href, data, {
            headers: {
            'Content-Type': 'application/json'
          }});
          if (response.ok) {
            // Data sent successfully
            console.log('Form sent successfully');
            result = true;
          } else {
            // Error handling
            console.error('Failed to send form');
          }
    } catch (err) {
        console.error('Error:', err);
    }
    return result;
}

const getSanitizedData = (data) => {
    const sanitizedData = {};
    for (let key in data) {
        sanitizedData[key] = DOMPurify.sanitize(data[key])
    }
    return sanitizedData;
}