import AboutHeader from "./AboutHeader";
import AboutCategories from "./AboutCategories";
import AboutLidabroPreview from "./AboutLidabroPreview";

function About() {
    return (
        <div id="About" className="flex flex-col gap-14 mb-10 xl:gap-20 xl:mb-[105px] lg:mb-12 scroll-mt-[150px] lg:scroll-mt-[300px]">
            <AboutHeader />
            <AboutCategories />
            <AboutLidabroPreview />
        </div>
    )
}

export default About;